import React, { useLayoutEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import slugify from 'slugify';

import Seo from '../components/SEO';
import breakpoints from '../services/breakpoints';
import Layout from '../components/Layout';

interface Props {
  data: {
    meta: {
      nodes: { title: string }[];
      mainColor: string;
    };
    thumbs: {
      edges: { node: IGatsbyImageData }[];
    };
  };
}

const useStyles = createUseStyles({
  container: {
    display: 'grid',
    columnGap: 24,
    rowGap: 24,
    justifyContent: 'center',

    gridTemplateColumns: 'repeat(1, 250px)',

    [breakpoints.m]: {
      gridTemplateColumns: 'repeat(2, 250px)',
    },
    [breakpoints.l]: {
      gridTemplateColumns: 'repeat(3, 280px)',
    },
  },

  item: {
    display: 'block',
    borderRadius: '8%',
    overflow: 'hidden',
  },
});

function IndexPage({ data }: Props) {
  const classes = useStyles();
  const meta = [...data.meta.nodes].reverse();
  const thumbs = [...data.thumbs.edges].reverse();

  useLayoutEffect(() => {
    document.body.style.backgroundColor = '#333';
  }, []);
  console.log(meta);
  return (
    <Layout>
      <Seo />
      <div className={classes.container}>
        {meta.map((painting, n) => {
          const image = getImage(thumbs[n].node);

          if (!image) {
            return null;
          }

          return (
            <div key={painting.title}>
              <Link
                className={classes.item}
                to={`/paintings/${slugify(painting.title, { lower: true })}`}
              >
                <GatsbyImage
                  image={image}
                  alt={`go to "${painting.title}" page`}
                />
              </Link>
            </div>
          );
        })}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query Paintings {
    meta: allJson(sort: { fields: parent___internal___description }) {
      nodes {
        title
      }
    }
    # paintings: allFile(
    #   sort: { fields: [dir, name] }
    #   filter: { sourceInstanceName: { eq: "paintings" } }
    # ) {
    #   list: distinct(field: dir)
    # }
    thumbs: allFile(
      sort: { fields: [dir, name] }
      filter: { base: { glob: "thumb.*" } }
    ) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 300, height: 300)
          }
        }
      }
    }
  }
`;

export default IndexPage;
